import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import { Distriboissons } from '../../../../../../pages/private/Foodservice/Distriboissons/DistriboissonsMaquettes'


export const FranceBoissonsRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <Distriboissons />,
        title: PagesName.DASHBOARD,
    },

]
